/* src/App.css */
.app {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.video-thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.video-thumbnail {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    opacity: 0; /* Start hidden */
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.video-thumbnail img {
    width: 300px;
    height: 300px;
    cursor: pointer;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.video-thumbnail:hover img {
    transform: scale(1.1);
}

.video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: tv-turn-on 0.5s forwards;
}

.video-popup.closing {
    animation: tv-turn-off 0.5s forwards;
}

.video-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.video-popup-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    z-index: 10;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    transform: scale(0.5);
    animation: tv-turn-on-content 0.5s forwards;
}

.video-popup-content.closing {
    animation: tv-turn-off-content 0.5s forwards;
}

.video-popup video {
    width: 100%;
    margin-top: 20px;
    max-width: 1000px;
    max-height: 400px;
    border-radius: 5px;
}

.video-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.video-thumbnail .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    transition: 0.5s ease;
    background-color: rgba(31, 31, 31, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-thumbnail .hovertext {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: translateY(20px);
    pointer-events: none; /* Ensure the pseudo-element doesn't interfere with hover */
}

.video-thumbnail:hover .overlay {
    opacity: 0;
}

.video-thumbnail:hover .hovertext {
    opacity: 1;
    transform: translateY(0);
}

.video-thumbnail .text {
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    word-wrap: break-word;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 0, 0, 0.1), 0 0 40px rgba(0, 0, 0, 0.1); /* Radial gradient-like shadow */
}

@keyframes tv-turn-on {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes tv-turn-on-content {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes tv-turn-off {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes tv-turn-off-content {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(0.5);
        opacity: 0;
    }
}

@keyframes scale {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.05);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .video-thumbnails {
        display: flex;
        flex-wrap: wrap;
        gap: 0px;
        justify-content: center;
        align-items: center;
    }

    .video-thumbnail img {
        width: 100vw;
        height: 200px;
        object-fit: cover;
    }

    .app {
        padding: 0px;
    }

    main {
        padding: 0;
        padding-bottom: 2rem;
    }
}

.video-thumbnail.appear {
    opacity: 1;
    transform: translateY(0);
}