:root {
    --color-bg1: rgb(89, 123, 96);
    --color-bg2: rgb(107, 75, 104);
    --color1: 120, 173, 131;
    --color2: 24, 78, 90;
    --color3: 114, 172, 179;
    --color4: 152, 119, 243;
    --color5: 245, 225, 229;
    --color-interactive: 245, 225, 229;
    --circle-size: 80%;
    --blending: hard-light;
    --page-padding: 0px;
}

.App {
    position: relative;
    z-index: 1;
    /* Ensure the main content is above the gradient background */
}

.page-container {
    padding: var(--page-padding);
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    :root {
        --page-padding: 0; /* Set a smaller padding for mobile devices */
    }
    
    .page-container {
        padding: var(--page-padding);
    }
}