/* src/fonts.css */
@font-face {
    font-family: 'CaviarDreams';
    src: url('./assets/fonts/CaviarDreams.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CaviarDreams';
    src: url('./assets/fonts/CaviarDreams_Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CaviarDreams';
    src: url('./assets/fonts/CaviarDreams_Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'CaviarDreams';
    src: url('./assets/fonts/CaviarDreams_BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Gontserrat";
    src: url('./assets/fonts/Gontserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Gontserrat";
    src: url('./assets/fonts/Gontserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Gontserrat";
    src: url('./assets/fonts/Gontserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Gontserrat";
    src: url('./assets/fonts/Gontserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Gontserrat";
    src: url('./assets/fonts/Gontserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Gontserrat";
    src: url('./assets/fonts/Gontserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

