@import './fonts.css';

html,
body {
  font-family: "Gontserrat", sans-serif;
  font-weight: 200;
  margin: 0;
  padding: 0;
}

h1,
p {
  text-align: center;
}

.text-container {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 96px;
  color: white;
  opacity: 0.8;
  user-select: none;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}


footer {
  // background-color: #ffffff46;
  color: black;
  text-align: center;
  bottom: 0;
}

main {
  padding: 2rem;
  padding-bottom: 2rem;
  /* To avoid overlap with the footer */
}

main a {
  text-decoration: none;
}

main a:hover {
  color: var(--color-bg1);
}


@media (max-width: 768px) {
  h1 {
    padding-top: 2rem;
  }
}