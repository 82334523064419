.logo-container {
  margin-right: auto;
}

.logo {
  height: 50px; /* Adjust the size as needed */
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 1px;
  background-color: #333;
  margin: 5px 5px;
  transition: 0.5s;
}

.change .bar1 {
  transform: translate(0, 5.5px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -5.5px) rotate(45deg);
}

.navbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem;
  position: relative;
}

nav {
  display: flex;
  width: 100wh;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem;
  z-index: 15;
}

nav a {
  font-family: "Gontserrat";
  font-weight: 200;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}
nav a:hover {
  color: #1c8d6b;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: black;
  text-decoration: none;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 1rem;
  /* Space between navbar items */
}

.nav-links li a {
  color: black;
  text-decoration: none;
}

@media (max-width: 768px) {
  .navbar {
    position: sticky;
    top: 0;
    margin: 0;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    top: 100%;
    right: 0;
    width: 200px;
    max-height: 0; /* Start collapsed */
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  }

  .nav-links.visible {
    max-height: 500px; /* Adjust based on your menu content */
    opacity: 1;
    visibility: visible;
  }

  .nav-links li {
    padding: 10px 0;
    margin: 0 0;
    background-color: #ffffff;
    /* Space between navbar items in vertical layout */
  }

  .menu-icon {
    display: block;
  }
  
  .logo {
    height: 40px;
  }
}