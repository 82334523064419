.icons {
   font-size: 1.5rem;
}

a {
   color: black;
}

a:hover .icons{
   color: rgb(46, 128, 11) !important;
}

.copyright {
   font-size: 0.8rem;
}