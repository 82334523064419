.introduction {
    margin: 20px;
}

.brands {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
}



.brands ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .brands li {
    transition: transform 0.3s ease, font-size 0.3s ease, opacity 0.3s ease;
    font-size: 16px; /* Initial font size */
    transform: scale(1); /* Initial scale */
    opacity: 1; /* Initial opacity */
    text-align: center;
  }

  /* @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
} */

@media (max-width: 768px) {
    .introduction, .paragraphs {
        margin: 40px;
    }

}